<template>
  <b-tabs
    content-class="col-12 col-md-12 mt-12 mt-md-0"
    pills
    nav-wrapper-class="col-md-12 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('General') }}
          </span>
      </template>

      <order-save v-if="isLoad" />
    </b-tab>
    <!--/ general tab -->

    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Files') }}
          </span>
      </template>

      <order-files v-if="isLoad" />
    </b-tab>
    
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">
          {{ $t('Order Result') }}
          </span>
      </template>
      <order-result v-if="isLoad" />
    </b-tab>
    <!--/ Files tab -->

  </b-tabs>
</template>

<script>
import OrderSave from './OrderSave.vue'
import OrderFiles from './OrderFiles.vue'
import OrderResult from './order-results/OrderResult.vue'

export default {
  components: {
    OrderSave,
    OrderFiles,
    OrderResult,
  },
  data() {
    return {
      isLoad: false,
    }
  },
  methods: {
    fetchItem() {
      const paramId = this.$router.currentRoute.params.id;
      if (parseInt(paramId, 10) !== 0) {
        this.$store.dispatch('orderManagement/fetchOrder', { id: paramId })
          .then(() => {
            this.isLoad = true;
          })
      } else {
        this.isLoad = true;
      }
    },
  },
  created() {
    this.$store.commit('orderManagement/resetOrderState');
    this.fetchItem();
  },
}
</script>
